import React from 'react';
import Testimonials from '../misc/testimonials/Testimonials';

const Reviews = () => {
  return (
    <React.Fragment>
      <Testimonials />
    </React.Fragment>
  );
};

export default Reviews;
