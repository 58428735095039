import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

const MenuBar = () => {
  return (
    <footer>
      <div className="footer">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/AboutUs">About Us</Link>
          </li>
          <li>
            <Link to="/ContactUs">Contact Us</Link>
          </li>
        </ul>
        <ul>
          <li>
            <a href="mailto:james.kastrup@efcstax.com">
              james.kastrup@efcstax.com
            </a>
          </li>
          <li>
            <a href="tel:(513) 891-8828"> (513) 891-8828</a>
          </li>
        </ul>
        <p>&copy; EFCS. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default MenuBar;
