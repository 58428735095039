import React from 'react';
import Featurette from '../misc/featurette/Featurette';
import Header from '../misc/header/Header';
import Line from '../misc/line/Line';
import user_f from '../../images/user_f.png';
import '../misc/testimonials/Testimonials.css';
import Pgraph from '../misc/pgraph/Pgraph';

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <Featurette />
      <section
        className="content-container"
        style={{ clear: 'both', paddingBottom: '200px', paddingTop: '3rem' }}
      >
        <div className="block">
          <h2 id="efcsTaxPreparation">
            Tax Preparation{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Filing Made Easy
            </span>
          </h2>
          <Pgraph>
            EFCS tax return preparation is available for almost everyone! With
            35 years of experience, you can have peace of mind knowing that EFCS
            has the knowledge and ability to get the most out of your tax
            returns. Individuals and small businesses alike can benefit greatly
            from our tax return preparation services. We service an array of
            clients, including Sole Proprietors, Limited Liability Companies,
            Partnerships, Sub S Corporations, as well as Estates & Trusts.
          </Pgraph>
          <Pgraph>
            <a href="/Planning">
              Click here to learn more about tax planning and preparation.
            </a>
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2 id="efcsAdvantage">
            EFCS Advantage{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Experience & Knowledge
            </span>
          </h2>
          <Pgraph>
            As a member of the Ohio Bar since 1983, Jim Kastrup with EFCS can
            provide you with every advantage when dealing with financial
            challenges in the modern world. EFCS also uses modern technology to
            help clients during the pandemic. All client data, electronic and/or
            personal information is secured using encrypted transmission.
            Meetings can be arranged as a video conference or in person, when
            permitted.
          </Pgraph>
          <Pgraph>
            <a href="/Services">Click here to learn more about our services.</a>
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Client Testimonials{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              EFCS Works for You
            </span>
          </h2>
          <div className="cust-container width-75">
            <img src={user_f} alt="Avatar" style={{ width: '90px' }} />
            <p>
              <span>Hannah L.</span> Client since 2023
            </p>
            <p>
              This was the least stressful tax season I've ever had! Using EFCS
              was so easy. Jim was easy to work with and very knowledgeable.
              Everything is online now which means he could complete everything
              in Cincinnati while I live in Columbus. EFCS's rates are very
              reasonable and we are already taking advantage of the referral
              discount! I can't say enough good things about our experience with
              EFCS. We are definitely going to be repeat customers and keep
              referring him to all of our family and friends.
            </p>
          </div>
          <Pgraph>
            EFCS is here to help you. Read our reviews and see for yourself!
          </Pgraph>

          <Pgraph>
            <a href="/Reviews">Click here to read more.</a>
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Referral Discounts{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Save Without Limits
            </span>
          </h2>
          <Pgraph>
            Word of mouth advertising continues to be the largest source of new
            clients, and we want to continue to encourage that by offering a
            referral discount program. Any existing client who refers someone
            who signs an engagement letter and uses our service will receive a
            $100 credit on their next invoice.
          </Pgraph>
          <Pgraph>
            There are no limitations as to the number of referrals that can be
            made and credits in excess of the current year's fees will be
            carried forward.
          </Pgraph>
          <Pgraph>
            <em>
              Referral Discounts not available in conjunction with any other
              discounts or offers
            </em>
          </Pgraph>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
