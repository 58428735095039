import React from 'react';
import MenuBar from '../components/navigations/menuBar/MenuBar';
import Footer from '../components/navigations/footer/Footer';

import './Layout.css';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <MenuBar />

      <main className="main-content">{children}</main>

      <Footer />
    </React.Fragment>
  );
};

export default Layout;
