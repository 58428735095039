import React from 'react';
import { Link } from 'react-router-dom';
import './Featurette.css';

const FeaturetteFragment = ({ children }) => {
  return (
    <div className="columns">
      <ul className="featurette">{children}</ul>
    </div>
  );
};

const Home = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <FeaturetteFragment>
          <li className="col-header" style={{ backgroundColor: '#4f50af' }}>
            Tax Preparation
          </li>
          <li>
            Tax planning and preparation for individuals and small businesses
          </li>
          <li>Over 35 years of experience</li>
          <li>Maximize your deductions and minimize liability</li>
          <li>Keep more of your hard earned money</li>
          <li className="grey">
            <Link to="/Planning">
              <button className="button">Learn More</button>
            </Link>
          </li>
        </FeaturetteFragment>

        <FeaturetteFragment>
          <li className="col-header">EFCS Advantage</li>
          <li>Providing superior attention to detail</li>
          <li>
            Credentialed preparers serving clients across the eastern U.S.
          </li>
          <li>The resources and knowledge to help achieve your goals</li>
          <li>Your success is our success</li>
          <li className="grey">
            <Link to="/Services">
              <button className="button">Learn More</button>
            </Link>
          </li>
        </FeaturetteFragment>

        <FeaturetteFragment>
          <li className="col-header" style={{ backgroundColor: '#4f50af' }}>
            Referral Discounts
          </li>
          <li>An easy way to save</li>
          <li>
            Lower your tax preparation fees by referring potential clients
          </li>
          <li>No limits on your savings</li>
          <li>
            For existing clients only. If you are not currently a client, give
            us a call!
          </li>
          <li className="grey">
            <Link to="/Reviews">
              <button className="button">Learn More</button>
            </Link>
          </li>
        </FeaturetteFragment>
      </section>
    </React.Fragment>
  );
};

export default Home;
