// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/space.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header {\n  color: white;\n  background: #4f50af;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  min-height: 50vh;\n  padding-top: 20vh;\n  text-align: center;\n  position: relative;\n  z-index: 1;\n  overflow: hidden;\n  background-position: center; /* Center the image */\n  background-repeat: no-repeat; /* Do not repeat the image */\n  background-size: cover; /* Resize the background image to cover the entire container */\n}\n\nheader h1 {\n  font-size: 4rem;\n  margin: 0 0 1rem;\n}\n\nheader h2 {\n  font-weight: 300;\n  font-size: 2rem;\n  margin: 0 0 1rem;\n}\n\nheader a {\n  color: #ffff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/misc/header/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,yDAAkD;EAClD,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,2BAA2B,EAAE,qBAAqB;EAClD,4BAA4B,EAAE,4BAA4B;EAC1D,sBAAsB,EAAE,8DAA8D;AACxF;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":["header {\n  color: white;\n  background: #4f50af;\n  background-image: url('../../../images/space.jpg');\n  min-height: 50vh;\n  padding-top: 20vh;\n  text-align: center;\n  position: relative;\n  z-index: 1;\n  overflow: hidden;\n  background-position: center; /* Center the image */\n  background-repeat: no-repeat; /* Do not repeat the image */\n  background-size: cover; /* Resize the background image to cover the entire container */\n}\n\nheader h1 {\n  font-size: 4rem;\n  margin: 0 0 1rem;\n}\n\nheader h2 {\n  font-weight: 300;\n  font-size: 2rem;\n  margin: 0 0 1rem;\n}\n\nheader a {\n  color: #ffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
