import React from 'react';
import Line from '../misc/line/Line';
import Pgraph from '../misc/pgraph/Pgraph';

const Services = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>EFCS Services</h2>
          <Pgraph>
            EFCS offers an array of services to help our clients meet their
            financial challenges. Below is a list of some of the services EFCS
            has to offer. If you have any questions about our services and what
            we can do for you, please call{' '}
            <a href="tel:(513) 891-8828"> (513) 891-8828</a>.
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Tax Planning{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              The key to a Successful Return
            </span>
          </h2>
          <Pgraph>
            Optimizing estimated payment projections and withholdings.
            Coordinating investments, retirement and business planning with
            income taxes.
          </Pgraph>
          <Pgraph>
            <a href="/Planning">Click here to learn more about tax planning.</a>
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Tax Preparation and e-filing{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Know the Client, Understand the Return
            </span>
          </h2>
          <Pgraph>
            <ul className="prep-list unstyled-list">
              <li>
                <span className="left-align">Individual</span>
                <span className="right-align">1040</span>
              </li>
              <li>
                <span className="left-align">Sole Proprietors</span>
                <span className="right-align">Sch C</span>
              </li>
              <li>
                <span className="left-align">Single or Multi member LLC</span>
                <span className="right-align">Sch C / 1065</span>
              </li>
              <li>
                <span className="left-align">Partnerships</span>
                <span className="right-align">1065</span>
              </li>
              <li>
                <span className="left-align">Sub S Corps</span>
                <span className="right-align">1120S</span>
              </li>
              <li>
                <span className="left-align">Estate & Trusts</span>
                <span className="right-align">1041</span>
              </li>
              <li>
                <span className="left-align">Non-Profit</span>
                <span className="right-align">990</span>
              </li>
            </ul>
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Debt Settlements and Negotiations{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Be Represented
            </span>
          </h2>
          <Pgraph>
            On your behalf, EFCS can communicate and negotiate with the IRS,
            state, or local taxing authorities to bring you into compliance and
            settle your delinquency. Procrastinating and ignoring the problem
            only increases the penalties and interest and may result in liens
            being levied on your property or your wages being garnished. Having
            an experienced professional like EFCS represent you will improve the
            final outcome and provide greater peace of mind. Avoid dealing with
            the IRS unrepresented.
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            Preparing and e-filing Amended and Delinquent Returns{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              A Second Opportunity
            </span>
          </h2>
          <Pgraph>
            If you find yourself procrastinating or avoiding dealing with past
            due tax returns, let us assist you with the process. Our experience
            working with federal, state and local taxing authorities have
            reduced stress for clients and brought them into compliance.
          </Pgraph>
          <Pgraph>
            For those individuals with self-employment income who prepared their
            own returns, let our preparers review those returns for missed
            opportunities, which may be significant.
          </Pgraph>
        </div>

        <Line />

        <div className="block">
          <h2>
            W-2 and 1099 Preparation and e-filing for Businesses{' '}
            <span className="title-span" style={{ color: '#4f50af' }}>
              Attention to Detail and Service
            </span>
          </h2>
          <Pgraph>
            There are times when small business owners try to manage all of
            their administrative duties themselves, but may lack the requisite
            knowledge, experience or resources. In these instances, EFCS can
            provide such assistance. Talk to our experienced staff about making
            your business operations more efficient.
          </Pgraph>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;
